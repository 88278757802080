import React from 'react';

import './css/notfound.css';

function NotFound() {
    return(
        <div className="notfound">
            <div className="notfound_info">
                404 Not Found
            </div>
        </div>
    );
}

export default NotFound;